<template>
  <v-container fluid>
    <v-row class="pt-10 mt-10" justify="center" no-gutters>
      <v-col cols="12" class="pb-10">
        <v-row justify="center" no-gutters>
          <v-col cols="9" md="4" class="text-h3 text-center font-weight-medium d-flex justify-center">Obtenez votre essai gratuit</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pb-10">
        <v-row justify="center" no-gutters>
          <v-col cols="9" md="4" class="body-2 text-center"
            >Essayer par vous-même, c'est gratuit. Notre équipe reviendra vers vous avec un compte d'essai et répondra à toutes
            vos questions.
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="7" class="pt-10 mt-10">
        <v-row justify="center" no-gutters>
          <div class="neon-cruip" style="width: 100%">
            <div class="has-animations is-loaded" style="width: 100%" id="my-body2">
              <c-cta split />
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CCta from "@/components/cruip_template/sections/Cta.vue";

export default {
  name: "EssaiGratuit",
  components: {
    CCta,
  },
};
</script>

<style lang="scss" scoped></style>
